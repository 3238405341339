'use strict';

// submit trackorder modal
$(document).on('submit', '.trackorder', function (e) {
    e.preventDefault();
    var url = $(this).attr('action');
    var form = $(this);
    var redirectURL = window.location.href;

    $('.trackorder').find('.invalid-feedback').text('');
    $('.trackorder').find('.invalid-feedback').hide()

    $(".trackorder button").addClass("is__loading");

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $(form).serialize(),

        success: function (data) {
            $(".trackorder button").removeClass("is__loading");

            if(data.success) {
                window.location.href = data.redirectUrl;
            } else {
                $(".trackorder button").removeClass("is__loading");
                $('.trackorder').find('.invalid-feedback.title').show()
                $('.trackorder').find('.invalid-feedback.subtitle').show()
                $('.trackorder').find('.invalid-feedback.title').text(data.errorMessage.title);
                $('.trackorder').find('.invalid-feedback.subtitle').text(data.errorMessage.subtitle);
            }

        },
        error: function (err) {}
    });
});

//submit cancel order modal
function responseCancelOrder (success){
    $('.modalCancelOrderContent .cancelBody-text').hide();
    var response;
    var message;

    if(success){
        response ="Cancelamento solicitado com sucesso!"
        message ="Sua pedido já está em análise de cancelamento."
    } else{
        response ="Erro no cancelamento!"
        message ="Devido a um problema técnico, não foi possível cancelar seu pedido. Por favor, tente novamente mais tarde ou entre em contato conosco através de nossos outros canais de atendimento."
    }
    
    var classResponse = success? 'response-success':'response-error';
    var htmlString = '<div class="modal-response-cancel mb-3 '+classResponse+'">'
    +'<p>'+response+'</p>'
    +'<span>'+message+'</span>'
    +'</div>'
    $('.modalCancelOrderContent .modal-body').append(htmlString)

    setTimeout(function(){
        location.reload()
    }, 1500);
}

$(document).on('submit', '.cancelorder', function (e) {
    e.preventDefault();
    var url = $(this).attr('action');
    var form = $(this);
    var orderNumber = form.find('input[name="orderId"]').val();
    var orderID = JSON.stringify({ orderID: orderNumber })
    $(".modal-cancel-order button[name=submit]").addClass("is__loading");

    $.ajax({
        url: url,
        type: 'post',
        contentType: 'application/json',
        dataType: 'json',
        data: orderID,
        success: function (data) {
            $(".modal-cancel-order button[name=submit]").removeClass("is__loading");
            if(data.success) {
                responseCancelOrder(true)
            } else {
                responseCancelOrder(false)
            }
        },
        error: function (err) {
            $(".modal-cancel-order button[name=submit]").removeClass("is__loading");
            responseCancelOrder(false)

        }
    });
});

//set orderNumber in modal and instructions about cancellation

$(document).on('click','.cancelOrder_button', function(){
    var orderNumber = $(this).data('id');
    var cancelType = $(this).data('modal')

    var whatsapp = 'https://wa.me/5511995690583'
    var email = 'suporte@suprija.com'
    var htmlString =  '<div class="modal-instructions-cancel mb-3">'
    +'<p>Para cancelar seu pedido, entre em contato com nosso suporte. Você pode nos enviar um e-mail para ' 
    +'<a href="mailto:'+email+'">'+email+'</a>'+' ou nos chamar pelo '
    +'<a href="'+whatsapp+'" target="_blank">WhatsApp</a>'+'</p>'
    +'</div>';

    $('.modalCancelOrderContent .modal-body').append(htmlString)

    //verificação se é possivel executar o cancelamento ou contatar o suporte para cancelamento
    if(cancelType==='cancellable'){
        $('input[name=orderId]').val(orderNumber);
        $('.modalCancelOrderContent .modal-response-cancel').remove();
        $('.modalCancelOrderContent .modal-instructions-cancel').remove();
        $('.modalCancelOrderContent .cancelBody-text').show();
    }else{
        $('.modalCancelOrderContent .modal-instructions-cancel').remove();
        $('.modalCancelOrderContent .cancelBody-text').hide();
        $('.modalCancelOrderContent .modal-body').append(htmlString)
    }
})

$(document).ready(function() {
    // qr code pix
    function startCountdown($this) {
        var totalTime = 1800;
        var $generateBtn = $this;

        console.log($generateBtn);

        var $parent = $generateBtn.closest('.dataOrder-container__item');
        var $qrcodeContainer = $parent.find('.qrcodeContainer');

        var $countdown = $parent.find('.countdown');

        $generateBtn.addClass('is__loading');

        setTimeout(() => {
            $generateBtn.addClass('d-none');
            $qrcodeContainer.removeClass('d-none');

            var countdown = setInterval(function() {
                var minutes = Math.floor(totalTime / 60);
                var seconds = totalTime % 60;

                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                $countdown.text(minutes + ':' + seconds);

                if (totalTime <= 0) {
                    clearInterval(countdown);
                    $qrcodeContainer.addClass('d-none');
                    $generateBtn.removeClass('d-none');
                    $generateBtn.removeClass('is__loading');
                }

                totalTime--;
            }, 1000);
        }, 1000);
    }
    $('.generateBtn').on('click', function() {
        var $this = $(this)
        startCountdown($this);
    });
    $('.copyBtn').on('click', function() {
        $(this).addClass('is__loading');
        var $this = $(this);

        var $parent = $this.closest('.dataOrder-container__item');
        var inputValue = $parent.find('.pixInput');
        navigator.clipboard.writeText(inputValue).then(function() {
            $this.removeClass('is__loading');
            $('.container-popUpCopy').fadeIn(800).fadeOut();
        }).catch(function(err) {
            $this.removeClass('is__loading');
            console.error('Erro ao copiar para a área de transferência: ', err);
        });
    });

    $('.collapse-toggle').on('click', function() {
        var $this = $(this);
        var $icon = $this.find('i');

        if ($this.attr('aria-expanded') === 'true') {
            // Quando o accordion está expandido
            $this.html('Abrir <i class="icon-chevron-down"></i>');
        } else {
            // Quando o accordion está colapsado
            $this.html('Fechar <i class="icon-chevron-up"></i>');
        }
    });
});
